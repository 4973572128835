import { showMessage } from './message'
import axios from 'axios'
import store from './stores'

axios.defaults.baseURL = 'https://api.youpu.fit/prod'
axios.defaults.timeout = 5000
// axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '/' : 'http://api.vikingship.xyz/api/'
// const instanceAxios = axios.create({
//   // baseURL: 'http://107.148.241.51:8001/',
//   // baseURL: 'https://gvt0k35ml9.execute-api.eu-west-3.amazonaws.com/prod',
//   baseURL: 'https://api.youpu.fit/prod',
//   timeout: 90000,
// })

axios.interceptors.response.use(
  (response) => {
    console.log('interceptors: ', response)
    if (response.data.code != 0) {
      store.commit('setError', { status: true, message: response.data.message })
      showMessage(response.data.message, 'error')
    }
    return response
  },
  (e) => {
    const { error } = e.response.data
    store.commit('setError', { status: true, message: error })
    // showMessage(error, 'error')
    console.log(error)
    return Promise.reject(error)
  }
)

/**
 *
 * @param url : /simulation-post
 * @param data
 * @returns
 */
export const post = (data = {}, url: string = '/simulation-post') => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(
        (res) => resolve(res.data),
        (err) => reject(err)
      )
  })
}

/**
 *
 * @param url
 * @param params
 * @returns
 */
export const get = (data = {}, url: string = '/simulation-get') => {
  return new Promise((resolve, reject) => {
    axios.get(url, { data }).then(
      (res) => resolve(res.data),
      (err) => reject(err)
    )
  })
}

export default axios
