<template>
<div class="title">注册</div>
<div class="formLogin">
    <el-row :gutter="0">
        <el-col :xs="4" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="grid-content" />
        </el-col>
        <el-col :xs="16" :sm="8" :md="8" :lg="8" :xl="8">
            <el-form ref="ruleFormRef" :model="ruleForm" status-icon :rules="rules" label-width="80px" labelPosition="left">
                <el-form-item label="手机号" prop="tel">
                    <el-input class="formLogin__input" v-model="ruleForm.tel" type="tel" autocomplete="off" />
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                    <div class="formLogin__codeInput">
                        <el-input v-model="ruleForm.code" type="tel" autocomplete="off">
                            <template #append>
                                <el-button class="formLogin__codeInput__codeBtn" text type="success" plain @click="getCode()" :loading="loadingBtnCode">{{ contentBtnCode }}</el-button>
                            </template></el-input>

                    </div>
                </el-form-item>
                <el-form-item label="密码" prop="pass">
                    <el-input class="formLogin__input" v-model="ruleForm.pass" type="password" autocomplete="off" />
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                    <el-input class="formLogin__input" v-model="ruleForm.checkPass" type="password" autocomplete="off" />
                </el-form-item>
                <!-- <el-form-item label="激活码" prop="promo">
                    <el-input placeholder="(可选)" class="formLogin__input" v-model="ruleForm.promo" type="promo" autocomplete="off" />
                </el-form-item> -->
            </el-form>
            <div class="captchaDivPa">
                <div id="captcha" class="captchaDivSo">
                    <GeetestCaptcha :captcha-config="captchaConfig" />
                </div>

            </div>

            <div class="formLogin__btnLogin">
                <el-button class="formLogin__btn" type="primary" @click="submitForm(ruleFormRef)">注册</el-button>
                <el-button class="formLogin__btn" @click="resetForm(ruleFormRef)">重置</el-button>
            </div>
        </el-col>
        <el-col :xs="4" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="grid-content" />
        </el-col>
    </el-row>
    <!-- <el-dialog v-model="dialogFormVisible" title="是否已经购买激活码">
      <el-form label-width="80px" labelPosition="top">
        <el-form-item label="请输入激活码" prop="promo">
          <el-input class="formLogin__promo" v-model="ruleForm.promo" type="promo" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="submitPromo(ruleFormRef)">确定</el-button>
        </span>
      </template>
    </el-dialog> -->
</div>
</template>

<script lang="ts" setup>
import type {
    FormInstance
} from 'element-plus'
import {
    computed,
    reactive,
    ref,
    getCurrentInstance
} from 'vue'
import {
    showMessage
} from "../composables/message"
import {
    useStore
} from 'vuex'
import {
    useRouter
} from 'vue-router'
import axios, {
    post
} from '../composables/request'
import {
    ruleFormRef,
    ruleForm,
    validatePass,
    validatePass2,
    validateTel,
    validateCode
} from "../composables/validators"
import GeetestCaptcha from "./GeetestCaptcha.vue";
const router = useRouter()
const store = useStore()
store.commit('setCurrentRouteIndex', 7)
store.commit('setVisible', {
    type: 'backBtn',
    value: true
})
store.commit('setVisible', {
    type: 'progressBar',
    value: false
})
const dialogFormVisible = ref(false)
const loadingBtnCode = ref(false)
const timeBtnCode = ref(60)
const contentBtnCode = ref('发送')

const rules = reactive({
    pass: [{
        validator: validatePass,
        trigger: 'blur'
    }],
    checkPass: [{
        validator: validatePass2,
        trigger: 'blur'
    }],
    tel: [{
        validator: validateTel,
        trigger: 'blur'
    }],
    code: [{
        validator: validateCode,
        trigger: 'blur'
    }],
})

const getCode = () => {
    var result = (window as any).captchaObj.getValidate();
    console.log(result)
    if (!result) {
        showMessage("请先完成验证！", "warning")
        return;
    }
    console.log('getCode')
    post({
        "tel": ruleForm.tel
    }, "/sms-check").then(res => {
        console.log(res);
        ruleForm.code = res.data.smsCode;
    })
    loadingBtnCode.value = true
    contentBtnCode.value = timeBtnCode.value + 's后重发'
    let clock = window.setInterval(() => {
        timeBtnCode.value--
        contentBtnCode.value = timeBtnCode.value + 's后重发'
        if (timeBtnCode.value < 0) {
            //当倒计时小于0时清除定时器
            window.clearInterval(clock) //关闭
            contentBtnCode.value = '重发验证码'
            timeBtnCode.value = 60
            loadingBtnCode.value = false
        }
    }, 1000)
}

const submitForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return
    var result = (window as any).captchaObj.getValidate();
    console.log(result)
    if (!result) {
        showMessage("请先完成验证", "warning")
        return;
    }
    formEl.validate(valid => {
        if (valid) {
            console.log('submit!')
            dialogFormVisible.value = true
            post({
                tel: ruleForm.tel,
                pwd: ruleForm.pass,
                code: +ruleForm.code
                // type: 'resetPwd'
            }, '/sign').then(res => {
                store.commit("login", {
                    _id: res.data.uid,
                    tel: res.data.tel,
                    simuId: res.data.simuId
                })
                console.log('sign resultat:')
                console.log(res)
                // axios.defaults.headers.common['Authorization'] = res.data.token
                store.commit('setToken', res.data.token)
                post(store.state.persona).then(result => {
                    if (result.code === 10004) {
                        store.commit("setPersonaComplet", false)
                        router.push("/gender")
                        // toast(result?.msg)
                        console.log(result)
                    }
                    console.log('post simu res')
                    console.log('result report: ', result)
                    store.commit('setReport', result.data.report)
                    router.push('/client')
                })
            })
        } else {
            console.log('error submit!')
            return false
        }
    })
}

const resetForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return
    formEl.resetFields()
}
// declare global {
//     interface Window {
//         [propName: string]: never;
//     }
// }
const instance: any = getCurrentInstance();

let captchaConfig = reactive({
    config: {
        captchaId: "2fe861ac42d66109a12fd86686bef3ff",
        language: "zho",
        product: "float",
    },
    handler: captchaHandler,
});

function captchaHandler(captchaObj: any) {
    (window as any).captchaObj = captchaObj;
    captchaObj
        .appendTo("#captcha")
        .onReady(function () {
            console.log("ready");
        })
        .onNextReady(function () {
            console.log("nextReady");
        })
        .onBoxShow(function () {
            console.log("boxShow");
        })
        .onError(function (e: any) {
            console.log(e);
        })
        .onSuccess(function () {
            console.log("gee success");
            captchaConfig.config.product = "bind"
            if (captchaConfig.config.product === "bind") {
                validate();
            }
        });
}

const validate = () => {
    var result = (window as any).captchaObj.getValidate();
    console.log(result)
    if (!result) {
        showMessage("请先完成验证", "warning")
        return;
    }
    post(result, "/gee")
        .then((res: any) => {
            console.log(res)

            if (res.data.result) {
                console.log(res.data);
                showMessage("验证成功", "success")
            }
        });
}
</script>

<style lang="scss" scoped>
@import url('../styles/question.scss');

.formLogin {
    margin: 0 auto;
    margin-top: 0.2rem;

    &__codeInput {
        margin: 0 auto;
        width: 2rem;

        &__codeBtn {
            right: 0;
            padding-left: 0.08rem;
            padding-right: 0.08rem;
            font-size: .11rem;
        }
    }

    &__input {
        margin: 0 auto;
        width: 2rem;
    }

    &__promo {
        margin: 0 auto;
    }

    &__inputCode {
        margin-left: auto;
        width: 1rem;
    }

    &__btn {
        margin: 0 auto;
        width: 0.2rem;
        margin-left: 0.1rem;
    }

    &__btnLogin {
        margin: .2rem;
    }
}

#captcha {
    height: .5rem;
}

.captchaDivPa {
    display: flex;
    justify-content: center;
}

captchaDivSo {
    width: .1rem;
}
</style>
