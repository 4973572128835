import { AxiosRequestConfig } from 'axios'
import { Commit, createStore } from 'vuex'
import axios from './request'

export interface GlobalErrorProps {
  status: boolean
  code?: number
  message?: string
}

export interface GlobalDataProps {
  stage: string
  token: string
  countdown: number
  currentRouteIndex: number
  progressBarVisible: boolean
  // backBtnVisible: boolean
  conversationId?: string
  error: GlobalErrorProps
  persona: PersonaProps
  user: UserProps
  report: ReportProps[]
}
export interface UserProps {
  isLogin: boolean
  _id?: string
  email?: string
  tel?: string
  isAdmin?: boolean
  simuId?: string
  isPaid?: boolean
  isComplet?: boolean
}

export interface ReportProps {
  date: string
  breakfast: string
  launch: string
  supper: string
  kcal: string
}

export interface PersonaProps {
  gender?: string
  age?: number
  height?: number
  weight?: number
  sportLevel?: number
  duration?: number
  loseWeight?: number
  kcalTargetValue?: number
  tdeeValue?: number
  bmrValue?: number
}

const asyncAndCommit = async (url: string, mutationName: string, commit: Commit, config: AxiosRequestConfig = { method: 'get' }, extraData?: any) => {
  const { data } = await axios(url, config)
  if (extraData) {
    commit(mutationName, { data, extraData })
  } else {
    commit(mutationName, data)
  }
  return data
}

const checkStage = () => {
  const pathUrl = window.location.href
  let stage = ''
  if (pathUrl.startsWith('https://dev') || pathUrl.startsWith('http://')) {
    stage = 'dev'
  } else if (pathUrl.startsWith('https://you')) {
    stage = 'prod'
  } else {
    stage = 'unknow'
  }
  console.log('stage: ', stage)
  return stage
}

const store = createStore<GlobalDataProps>({
  state: {
    stage: checkStage(),
    token: localStorage.getItem('token') || '',
    countdown: Number(localStorage.getItem('countdown')) || 15 * 60 * 1000,
    currentRouteIndex: 0,
    progressBarVisible: true,
    // conversationId: '',
    error: { status: false },
    user: { isLogin: false },
    persona: JSON.parse(localStorage.getItem('persona') || '{}') as PersonaProps,
    // persona: (localStorage.getItem('persona') || {}) as PersonaProps,
    report: JSON.parse(
      localStorage.getItem('report') ||
        '[{"date":"星期一","breakfast":"","launch":"","supper":"","kcal":""},{"date":"星期二","breakfast":"","launch":"","supper":"","kcal":""},{"date":"星期三","breakfast":"","launch":"","supper":"","kcal":""},{"date":"星期四","breakfast":"","launch":"","supper":"","kcal":""},{"date":"星期五","breakfast":"","launch":"","supper":"","kcal":""},{"date":"星期六","breakfast":"","launch":"","supper":"","kcal":""},{"date":"星期日","breakfast":"","launch":"","supper":"","kcal":""}]'
    ),
  },
  mutations: {
    setPersonaComplet(state, isComplet: boolean) {
      state.user.isComplet = isComplet
    },
    login(state, rawData) {
      console.log('rawData', rawData)
      state.user = { ...state.user, ...rawData, isLogin: true }
    },
    logout(state) {
      state.token = ''
      state.user = { isLogin: false }
      localStorage.removeItem('token')
      delete axios.defaults.headers.common.Authorization
      localStorage.removeItem('persona')
      localStorage.removeItem('countdown')
      localStorage.removeItem('report')
    },
    setCurrentRouteIndex(state, index) {
      state.currentRouteIndex = index
    },
    setPersona(state, keyValue) {
      state.persona = { ...state.persona, ...keyValue }
      localStorage.setItem('persona', JSON.stringify(state.persona))
      console.log('persona', state.persona)
    },
    setReport(state, report) {
      state.report = report
      localStorage.setItem('report', JSON.stringify(report))
    },
    setCountdown(state, value) {
      state.countdown = value
      localStorage.setItem('countdown', value)
    },
    setToken(state, token) {
      state.token = token
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      localStorage.setItem('token', token)
    },
    setVisible(state, data) {
      state.progressBarVisible = data.value
      // if (data.type == 'backBtn') {
      //   state.backBtnVisible = data.value
      // } else if (data.type == 'progressBar') {
      // }
    },
    setError(state, e: GlobalErrorProps) {
      state.error = e
    },
    fetchCurrentUser(state, rawData) {
      state.user = { ...rawData.data, isLogin: true }
    },
  },
  actions: {
    fetchCurrentUser({ commit }) {
      return asyncAndCommit('/user-get', 'fetchCurrentUser', commit)
    },
  },
})

export default store

// {
//   gender: localStorage.getItem('gender') || '',
//   age: Number(localStorage.getItem('age')) || 0,
//   height: Number(localStorage.getItem('height')) || 0,
//   weight: Number(localStorage.getItem('weight')) || 0,
//   sportLevel: Number(localStorage.getItem('sportLevel')) || 0,
//   duration: Number(localStorage.getItem('duration')) || 0,
//   loseWeight: Number(localStorage.getItem('loseWeight')) || 0,
//   kcalTargetValue: Number(localStorage.getItem('kcalTargetValue')) || 0,
//   tdeeValue: Number(localStorage.getItem('tdeeValue')) || 0,
//   bmrValue: Number(localStorage.getItem('bmrValue')) || 0,
// },
