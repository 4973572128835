<template>
<div class="graph">
    <div class="graph__looseData">
        <div class="graph__looseData__weightFrom"><span>{{store.state.persona.weight}}</span>KG</div>
        <div class="graph__looseData__weightTo"><span>{{store.state.persona.weight-store.state.persona.loseWeight}}</span>KG</div>
    </div>
    <svg class="js-weight js-loose-weight" viewBox="0 0 285 196" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
        <defs>
            <linearGradient id="weight-down-chart-solid-fill" x1="142" y1="15" x2="142" y2="173" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
                <stop id="weight-down-chart-solid-fill-0" offset="0%" stop-color="rgba(176,237,151,.35)"></stop>
                <stop id="weight-down-chart-solid-fill-1" offset="100%" stop-color="rgba(176,237,151,0)"></stop>
            </linearGradient>
        </defs>
        <g id="weight-down-weight-down">
            <g id="weight-down-chart-grid">
                <line id="weight-down-line_2" x1="221.5" y1="172.5" x2="221.5" y2="0.5" fill="none" stroke="#9e9e9e" stroke-opacity="0.5" stroke-linecap="round" stroke-dasharray="8,12"></line>
                <line id="weight-down-line_1" x1="274.5" y1="172.5" x2="274.5" y2="0.5" fill="none" stroke="#9e9e9e" stroke-opacity="0.5" stroke-linecap="round" stroke-dasharray="8,12"></line>
                <line id="weight-down-line_3" x1="168.5" y1="172.5" x2="168.5" y2="0.5" fill="none" stroke="#9e9e9e" stroke-opacity="0.5" stroke-linecap="round" stroke-dasharray="8,12"></line>
                <line id="weight-down-line_4" x1="115.5" y1="172.5" x2="115.5" y2="0.5" fill="none" stroke="#9e9e9e" stroke-opacity="0.5" stroke-linecap="round" stroke-dasharray="8,12"></line>
                <line id="weight-down-line_5" x1="62.5" y1="172.5" x2="62.5" y2="0.5" fill="none" stroke="#9e9e9e" stroke-opacity="0.5" stroke-linecap="round" stroke-dasharray="8,12"></line>
                <line id="weight-down-line_6" x1="9.5" y1="172.5" x2="9.50001" y2="0.5" fill="none" stroke="#9e9e9e" stroke-opacity="0.5" stroke-linecap="round" stroke-dasharray="8,12"></line>
            </g>
            <path id="weight-down-chart-solid" d="M62,60.5C40.0898,45.0026,9,14,9,14v159h266v-25c0,0-25.5,9.5-53.5-18.5C206.596,114.596,203,100,169,100c-21,0-28.5-28-53.5-28.5-21.3258-.4265-33,3.5-53.5-11Z" opacity="0" fill="url(#weight-down-chart-solid-fill)"></path>
            <path id="weight-down-chart-curve" d="M9,14c0,0,31.0898,31.0026,53,46.5c20.5,14.5,32.1742,10.5735,53.5,11C140.5,72,148,100,169,100c34,0,37.596,14.596,52.5,29.5c28,28,53.5,18.5,53.5,18.5" fill="none" stroke="#b0ed97" stroke-width="3" stroke-linecap="round" stroke-dashoffset="311.43" stroke-dasharray="311.43"></path>
            <g id="weight-down-pointer_2_ts" transform="translate(274,149) scale(0,0)">
                <circle id="weight-down-pointer_2" r="7.5" transform="translate(0,0)" fill="#fff" stroke="#63af76" stroke-width="3"></circle>
            </g>
            <g id="weight-down-pointer_1_ts" transform="translate(10,14) scale(0,0)">
                <circle id="weight-down-pointer_1" r="8" transform="translate(0,0)" fill="#fff" stroke="#63af76" stroke-width="4"></circle>
            </g><text id="weight-down-value-1" dx="0" dy="0" font-family="&quot;weight-down:::Roboto&quot;" font-size="18" font-weight="700" opacity="0" fill="#fff">
                <tspan id="weight-down-tspan7" x="31" y="20.1523" font-family="&quot;weight-down:::Roboto&quot;" font-size="18" font-weight="700" font-style="normal" fill="#fff">
                </tspan>
            </text><text id="weight-down-value-2" dx="0" dy="0" font-family="&quot;weight-down:::Roboto&quot;" font-size="18" font-weight="700" opacity="0" fill="#fff">
                <tspan id="weight-down-tspan8" x="230" y="125.152" font-family="&quot;weight-down:::Roboto&quot;" font-size="18" font-weight="700" font-style="normal" fill="#fff">
                </tspan>
            </text>
        </g>
    </svg>
</div>
</template>

<style lang="scss" scoped>
.graph {
    position: relative;

    &__looseData {

        &__weightFrom {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 20%;
            left: 10%;
        }

        &__weightTo {
            position: absolute;
            transform: translate(-50%, -50%);
            bottom: 30%;
            left: 90%;
        }
    }
}

.weightFrom {
    position: relative;
    margin-top: .5rem;
}

#weight-down-chart-solid {
    animation: weight-down-chart-solid_c_o 3000ms linear 1 normal forwards
}

@keyframes weight-down-chart-solid_c_o {
    0% {
        opacity: 0
    }

    3.333333% {
        opacity: 0
    }

    20% {
        opacity: 1
    }

    100% {
        opacity: 1
    }
}

#weight-down-chart-curve {
    animation: weight-down-chart-curve_s_do 3000ms linear 1 normal forwards
}

@keyframes weight-down-chart-curve_s_do {
    0% {
        stroke-dashoffset: 311.43
    }

    16.666667% {
        stroke-dashoffset: 0
    }

    100% {
        stroke-dashoffset: 0
    }
}

#weight-down-pointer_2_ts {
    animation: weight-down-pointer_2_ts__ts 3000ms linear 1 normal forwards
}

@keyframes weight-down-pointer_2_ts__ts {
    0% {
        transform: translate(274px, 149px) scale(0, 0)
    }

    13.333333% {
        transform: translate(274px, 149px) scale(0, 0)
    }

    23.333333% {
        transform: translate(274px, 149px) scale(1, 1)
    }

    100% {
        transform: translate(274px, 149px) scale(1, 1)
    }
}

#weight-down-pointer_1_ts {
    animation: weight-down-pointer_1_ts__ts 3000ms linear 1 normal forwards
}

@keyframes weight-down-pointer_1_ts__ts {
    0% {
        transform: translate(10px, 14px) scale(0, 0)
    }

    10% {
        transform: translate(10px, 14px) scale(1, 1)
    }

    100% {
        transform: translate(10px, 14px) scale(1, 1)
    }
}

#weight-down-value-1 {
    animation: weight-down-value-1_c_o 3000ms linear 1 normal forwards
}

@keyframes weight-down-value-1_c_o {
    0% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335)
    }

    10% {
        opacity: 1
    }

    100% {
        opacity: 1
    }
}

#weight-down-value-2 {
    animation: weight-down-value-2_c_o 3000ms linear 1 normal forwards
}

@keyframes weight-down-value-2_c_o {
    0% {
        opacity: 0
    }

    13.333333% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335)
    }

    23.333333% {
        opacity: 1
    }

    100% {
        opacity: 1
    }
}
</style>

<script lang="ts" setup>
import {
    useStore
} from 'vuex';
const store = useStore()
</script>
