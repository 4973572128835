<template>
<div>
    <div class="basicRecap">
        <div class="basicRecap__title">基本数据</div>
        <br />
        <BasicUnitView />
        <br />
        <br />
        根据您提供的信息，您的基础代谢率（BMR）为约<span class="basicRecap__dataHighlight">{{ store.state.persona.bmrValue }}</span>卡路里。 要计算您的总能量消耗量（TDEE），我们需要将您的BMR乘以您的活动系数。您活动等级是<span class="basicRecap__dataHighlight">{{
        getLevelStr(store.state.persona.sportLevel)
      }}</span>，因此，您的总能量消耗量（TDEE）约为<span class="basicRecap__dataHighlight">{{ store.state.persona.tdeeValue }}</span>卡路里/天。这意味着您每天需要摄入大约<span class="basicRecap__dataHighlight">{{ store.state.persona.tdeeValue }}</span>卡路里的热量来维持您现有的体重。

        <br />
        <br />
        请注意，这只是一个大致的估计，您的实际需求可能因个人因素，如生活方式，饮食，以及您的健康状况等因素而有所不同。

        <br /><br />
        根据您的目标计划: 在<span class="basicRecap__dataHighlight">{{ store.state.persona.duration }}</span>个月内减重<span class="basicRecap__dataHighlight">{{ store.state.persona.loseWeight }}</span>斤。为了达到您想要的减重效果，每日摄入卡路里的最多为<span class="basicRecap__dataHighlight">{{ store.state.persona.kcalTargetValue }}</span>。
        <br />
        <br />
        <div class="basicRecap__title">健身餐</div>
        <br />以下是以表格的形式为您定制的一周减肥饮食计划, 包括早餐,午餐和晚餐，让您每日摄入卡路里总量控制在<span class="basicRecap__dataHighlight">{{
        store.state.persona.kcalTargetValue
      }}</span>卡路里以下，并尽可能接近该值。同时尽可能贴合我们中国人的口味.
        <br />
    </div>
</div>
</template>

<script lang="ts" setup>
import BasicUnitView from "../components/layouts/BasicUnitView.vue";
import {
    useStore
} from 'vuex'
import {
    getLevelStr
} from '../composables/calcul'
const store = useStore()
</script>

<style lang="scss" scoped>
@import url('../styles/question.scss');

.basicRecap {
    text-align: left;

    &__title {
        font-size: 0.2rem;
        font-weight: bold;
    }

    &__dataHighlight {
        margin-left: 0.03rem;
        margin-right: 0.03rem;
        border-style: solid;
        border-radius: 0.05rem;
        border-width: 0.01rem;
        box-sizing: border-box;
        border-color: #95aa70;
        font-weight: bold;
    }
}
</style>
<!-- background-color: #95AA70; -->
