<template>
<div>
    <div class="title">请选择您的运动等级</div>
    <el-row :gutter="0" class="backframe">
        <el-col :xs="2" :sm="3" :md="5" :lg="5" :xl="7">
            <div class="grid-content" />
        </el-col>
        <el-col :xs="20" :sm="18" :md="14" :lg="14" :xl="10">
            <div>
                <el-button plain class="btn" @click="setState(1)">1: 久坐, 办公室的工作, 没什么运动</el-button>
            </div>
            <div>
                <el-button plain class="btn" @click="setState(2)">2: 轻量活动, 经常外出散步</el-button>
            </div>
            <div>
                <el-button plain class="btn" @click="setState(3)">3: 中等强度活动, 一周锻炼一至二次</el-button>
            </div>
            <div>
                <el-button plain class="btn" @click="setState(4)">4: 重等强度活动, 一周锻炼三至五次</el-button>
            </div>
            <div>
                <el-button plain class="btn" @click="setState(5)">5: 体力活, 一周锻炼五至七次</el-button>
            </div>
            <div>
                <el-button plain class="btn" @click="setState(6)">6: 专业运动员</el-button>
            </div>
        </el-col>
        <el-col :xs="2" :sm="3" :md="5" :lg="5" :xl="7">
            <div class="grid-content" />
        </el-col>
    </el-row>
</div>
</template>

<script lang="ts" setup>
import {
    ref,
    computed,
    ComputedRef
} from 'vue'
import {
    useStore
} from 'vuex'
import {
    useRouter
} from 'vue-router'
const showBtn = ref(false)
const store = useStore()
store.commit('setCurrentRouteIndex', 5)
store.commit('setVisible', {
    type: 'backBtn',
    value: true
})
store.commit('setVisible', {
    type: 'progressBar',
    value: true
})
const router = useRouter()
const sportLevelGet = computed(() => store.state.persona.sportLevel)
const sportLevel = ref(sportLevelGet.value)
const setState = (value: number) => {
    console.log(value)
    store.commit('setPersona', {
        sportLevel: value
    })
    router.push('/target')
}
</script>

<style lang="scss" scoped>
@import url('../styles/question.scss');

.inputWidget {
    margin-top: 10%;
}

.continueBtn {
    margin-top: 10%;
}

.btn {
    width: 3rem;
    height: 0.4rem;
    margin-top: .16rem;
    // height: auto;
    font-size: 0.13rem;
}
</style>
