<template>
<div class="title">登录</div>
<div class="formLogin">
    <el-row :gutter="0">
        <el-col :xs="4" :sm="8" :md="8" :lg="9" :xl="10">
            <div class="grid-content" />
        </el-col>
        <el-col :xs="20" :sm="8" :md="8" :lg="6" :xl="4">
            <el-form ref="ruleFormRef" :model="ruleForm" status-icon :rules="rules" label-width="60px" labelPosition="left">
                <el-form-item label="手机号" prop="tel">
                    <el-input class="formLogin__input" v-model="ruleForm.tel" type="tel" autocomplete="off" />
                </el-form-item>
                <el-form-item label="密码" prop="pass">
                    <el-input class="formLogin__input" v-model="ruleForm.pass" @keyup.enter="submitForm(ruleFormRef)" type="password" autocomplete="off" />
                </el-form-item>
            </el-form>
            <div>
                <el-button class="formLogin__btn" type="primary" @click="submitForm(ruleFormRef)">登陆</el-button>
                <el-button class="formLogin__btn" @click="register">注册</el-button>
            </div>
        </el-col>
        <el-col :xs="4" :sm="8" :md="8" :lg="9" :xl="10">
            <div class="grid-content" />
        </el-col>
    </el-row>
</div>
</template>

<script lang="ts" setup>
import {
    reactive,
    ref,
    computed
} from 'vue'
import {
    FormInstance
} from 'element-plus'
import {
    useStore
} from 'vuex'
import {
    useRouter,
} from 'vue-router'
import {
    post
} from '../composables/request'
const router = useRouter()
const store = useStore()
// const pathUrl = window.location.href
// console.log("pathUrl", pathUrl.value, window.location.href)

store.commit('setCurrentRouteIndex', 7)
store.commit('setVisible', {
    type: 'backBtn',
    value: true
})
store.commit('setVisible', {
    type: 'progressBar',
    value: false
})
const token = computed(() => store.state.token)
const stage = computed(() => store.state.stage)
const ruleFormRef = ref < FormInstance > ()
const ruleForm = reactive(stage.value == "dev" ? {
    pass: 'jhghhj',
    tel: '18898789879',
    code: '',
} : {
    pass: '',
    tel: '',
    code: '',
})
const validatePass = (rule: any, value: any, callback: any) => {
    if (value === '') {
        callback(new Error('请输入密码'))
    } else {
        if (ruleForm.pass !== '') {
            if (!ruleFormRef.value) return
            ruleFormRef.value.validateField('pass', () => null)
        }
        callback()
    }
}

const validateTel = (rule: any, value: any, callback: any) => {
    if (value === '') {
        callback(new Error('请输入手机号码'))
    } else if (value.length !== 11) {
        callback(new Error('手机号长度必须为11位'))
    } else {
        callback()
    }
}

const rules = reactive({
    pass: [{
        validator: validatePass,
        trigger: 'blur'
    }],
    tel: [{
        validator: validateTel,
        trigger: 'blur'
    }],
})

const getCode = () => {
    console.log('getCode')
}

const submitForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return
    formEl.validate(valid => {
        if (valid) {
            console.log('submit!', ruleForm)
            let bodyRequest = {
                tel: ruleForm.tel,
                pwd: ruleForm.pass
            }
            console.log('bodyRequest', bodyRequest)
            post(bodyRequest, '/login').then(res => {
                console.log(res)
                if (res.code == 0) {
                    store.commit('login', {
                        name: '',
                        tel: ruleForm.tel,
                        simuId: res.data.simuId,
                    })
                    store.commit('setToken', res.data.token)
                    router.push('/client')
                } else {
                    console.log("Login Err")
                    console.log(store.state.error.message)
                }
            })
        } else {
            console.log('error submit!')
            return false
        }
    })
}

const resetForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return
    formEl.resetFields()
}

const register = () => router.push('/sign')
</script>

<style lang="scss" scoped>
@import url('../styles/question.scss');

.formLogin {
    margin-top: 0.2rem;

    &__input {
        width: 2rem;
        margin: 0 auto;
    }

    &__btn {
        margin: 0 auto;
        width: 0.2rem;
        margin-left: 0.1rem;
    }
}
</style>
