<template>
<div>
    <div class="title">请输入您的年龄</div>
    <div class="centerDiv">
        <div class="inputGroup">
            <input type="text" required autocomplete="off" v-model="age" @keyup.enter="btnSubmit" :oninput="handleChange" />
            <label for="name">年龄</label>
        </div>
    </div>

    <div class="continueBtn" v-if="showBtn">
        <el-button type="success" plain @click="btnSubmit">确认并继续</el-button>
    </div>
</div>
</template>

<script lang="ts" setup>
import {
    ref,
    computed,
    ComputedRef
} from 'vue'
import {
    useStore
} from 'vuex'
import {
    useRouter
} from 'vue-router'
import {
    GlobalDataProps
} from "../composables/stores";
const store = useStore < GlobalDataProps > ()
const showBtn = ref(!!store.state.persona.age)
const handleChange = (value: number) => {
    showBtn.value = true

}
store.commit('setCurrentRouteIndex', 2)
store.commit('setVisible', {
    type: 'backBtn',
    value: true
})
store.commit('setVisible', {
    type: 'progressBar',
    value: true
})
const router = useRouter()
const ageGet = computed(() => store.state.persona.age)
const age = ref(ageGet.value)
const setState = (value: number) => {
    store.commit('setPersona', {
        age: value
    })
}
const btnSubmit = () => {
    setState(+age.value)
    router.push('/height')
}
</script>

<style lang="scss" scoped>
@import url('../styles/question.scss');

.input_widget {
    margin-top: .5rem;
    ;
}

.continueBtn {
    margin-top: .5rem;
}
</style>
