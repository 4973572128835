<template>
<div>
    <div class="top">
        <div class="top__content">
            <div class="top__content__loginBtn">
                <button class="buttonCool buttonCool-login iconfont" @click="login">&#xe64a; 登陆</button>
            </div>
            <div class="top__content__title">
                <span class="top__content__title__text">健康, 要</span>
                <img class="top__content__title__logo" :src="logoImg" />
            </div>
            <div class="top__content__subTitle">了解采用<span class="top__content__subTitle__keyword iconfont">智能健身餐&#xe615;轻断食</span>后能够减掉多少体重</div>
            <div class="top__content__startBtn">
                <span class="top__content__startBtn__btn">
                    <button class="buttonCool buttonCool-start iconfont" @click="setGender('女')">&#xe6a6; 女性</button>
                </span>
                <span class="top__content__startBtn__btn">
                    <button class="buttonCool buttonCool-start iconfont" @click="setGender('男')">&#xe6a7; 男性</button>
                </span>
            </div>

        </div>
    </div>
    <div class="iconfont slogon">智能健身餐 &#xe615; 轻断食</div>
    <div class="iconRef">
        <img class="iconRef__unit" :src="newyorkLogo" alt="">
        <img class="iconRef__unit" :src="healthlineLogo" alt="">
        <img class="iconRef__unit" :src="whoLogo" alt="">
        <img class="iconRef__unit" :src="buinLogo" alt="">
    </div>
    <div class="prez">
        <div class="prez__mask">
            <div class="prez__title">为什么选择我们!</div>
            <div class="glassCard prez__desc">
                <span class="iconfont prez__desc__icon"> &#xec5f; </span>
                <span class="prez__desc__text">深度集成迄今最强大的自然语言处理模型:ChatGPT</span>
            </div>
            <div class="glassCard prez__desc">
                <span class="iconfont prez__desc__icon"> &#xe62e; </span>
                <span class="prez__desc__text">基于2016获得诺贝尔医学奖的研究成果:细胞自噬</span>
            </div>
            <div class="glassCard prez__desc">
                <span class="iconfont prez__desc__icon"> &#xe634; </span>
                <span class="prez__desc__text">我们的食谱通过轻断食来激活细胞自噬的过程</span>
            </div>
            <div class="glassCard prez__desc">
                <span class="iconfont prez__desc__icon"> &#xe60c; </span>
                <span class="prez__desc__text">即刻给出免费饮食建议</span>
            </div>
            <div class="glassCard prez__desc">
                <span class="iconfont prez__desc__icon"> &#xe992; </span>
                <span class="prez__desc__text">专门为您定制</span>
            </div>
            <div class="glassCard prez__desc">
                <span class="iconfont prez__desc__icon"> &#xe66f; </span>
                <span class="prez__desc__text">一次订购就可解锁全部功能</span>
            </div>
        </div>

    </div>

</div>
</template>

<script lang="ts" setup>
import healthlineLogo from '../assets/healthline-logo.png'
import newyorkLogo from '../assets/newyorkLogo.png'
import whoLogo from '../assets/whoLogo.png'
import buinLogo from '../assets/buinLogo.png'
import {
    useStore
} from 'vuex'
import {
    useRouter
} from 'vue-router'
import logoImg from '~/assets/logoWhite-min.png'
import {
    ElButton,
    ElDialog
} from 'element-plus'
import {
    CircleCloseFilled
} from '@element-plus/icons-vue'
const router = useRouter()
const store = useStore()
const start = () => {
    router.push('/gender')
}
const login = () => {
    router.push('/login')
}
store.commit('setVisible', {
    type: 'backBtn',
    value: false
})
store.commit('setVisible', {
    type: 'progressBar',
    value: false
})
const setGender = (value: string) => {
    store.commit('setPersona', {
        gender: value
    })
    router.push('/age')
}
</script>

<style lang="scss" scoped>
@import url("../styles/question.scss");

.top {
    background: url('~/assets/bg_bk.jpg') 50% 0 no-repeat fixed;
    background-size: cover;
    height: 5rem;
    z-index: 0;

    &__content {
        height: 100%;
        z-index: 1;
        color: #ffffff;
        position: relative;

        &__loginBtn {
            position: absolute;
            right: 1.5%;
            top: 2%;
        }

        &__subTitle {
            padding-top: .5rem;
            font-size: 0.2rem;

            &__keyword {
                color: #d0f637;

            }
        }

        &__startBtn {
            margin-top: .2rem;

            &__btn {
                margin: .1rem;
            }
        }

        &__title {
            padding-top: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &__text {
                padding-top: .05rem;
                font-size: 0.48rem;
            }

            &__logo {
                width: 1rem;
            }
        }

    }
}

.slogon {
    margin-top: .6rem;
    margin-bottom: .6rem;
    font-size: .4rem;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.btn {
    background-color: transparent;
    color: #fff;
}

p {
    font-size: .16rem;
}

.prez {
    background: url('~/assets/bg_bk.jpg') 50% 0 no-repeat fixed;
    background-size: cover;
    height: 9rem;
    margin-top: .5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 0;

    &__mask {
        z-index: 1;
        width: 100%;
    }

    &__desc {
        padding: .2rem;
        margin-top: .5rem;
        margin-left: .2rem;
        margin-right: .2rem;
        color: #000000;
        font-size: .2rem;
        font-weight: bold;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        border: 2px solid #d0f637;

        &__icon {
            flex: 1;
            text-align: left;
            max-width: .3rem;
        }

        &__text {
            flex: 2;
            text-align: right;
        }
    }

    &__title {
        margin-top: .5rem;
        color: #fff;
        font-size: .4rem;
    }

    &__tab {
        padding: .3rem;
    }

    &__card {
        color: #f1f1f1;
        border-radius: 25px;
        padding: 20px;
        width: 6rem;
        height: .6rem;
        z-index: 1;

        &__title {
            font-size: .4rem;
        }
    }
}

.iconRef {
    color: #000;

    &__unit {
        height: .2rem;
        margin: .1rem;
    }
}

.imgGpt {
    background: url("~/assets/man-app_laqeqa.jpg");
    background-size: auto 10rem;
    background-repeat: no-repeat;
    background-position: center;
}

.imgNobel {
    background: url("~/assets/band-discover_duvtqu.jpg");
    background-size: auto 10rem;
    background-repeat: no-repeat;
}

$max-tab-count: 5;
$tab-wrap-border-radius: 6px;

.tab-wrap {
    transition: 0.3s box-shadow ease;
    border-radius: $tab-wrap-border-radius;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    list-style: none;
    background-color: #fff;
    margin: 40px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    &:hover {
        box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
    }
}

.tab {
    display: none;

    @for $i from 1 through $max-tab-count {
        &:checked:nth-of-type(#{$i})~.tab__content:nth-of-type(#{$i}) {
            opacity: 1;
            transition: 0.5s opacity ease-in, 0.8s transform ease;
            position: relative;
            top: 0;
            z-index: 100;
            transform: translateY(0px);
            text-shadow: 0 0 0;
        }
    }

    &:first-of-type:not(:last-of-type)+label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:not(:first-of-type):not(:last-of-type)+label {
        border-radius: 0;
    }

    &:last-of-type:not(:first-of-type)+label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:checked+label {
        background-color: #fff;
        box-shadow: 0 -1px 0 #fff inset;
        color: #9cd54f;
        font-size: .2rem;
        cursor: default;

        &:hover {
            box-shadow: 0 -1px 0 #fff inset;
            background-color: #fff;
        }
    }

    +label {
        box-shadow: 0 -1px 0 #eee inset;
        border-radius: $tab-wrap-border-radius $tab-wrap-border-radius 0 0;
        cursor: pointer;
        display: block;
        text-decoration: none;
        font-size: .2rem;
        color: #333;
        flex-grow: 3;
        text-align: center;
        background-color: #f2f2f2;
        user-select: none;
        text-align: center;
        transition: 0.3s background-color ease, 0.3s box-shadow ease;
        height: 50px;
        box-sizing: border-box;
        padding: 15px;

        &:hover {
            background-color: #f9f9f9;
            box-shadow: 0 1px 0 #f4f4f4 inset;
        }
    }

    &__content {
        text-align: left;
        padding: 10px 25px;
        background-color: transparent;
        position: absolute;
        width: 100%;
        z-index: -1;
        opacity: 0;
        left: 0;
        transform: translateY(-3px);
        border-radius: $tab-wrap-border-radius;
    }
}
</style>
