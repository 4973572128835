<script lang="ts" setup>
import {
    computed
} from 'vue'
import {
    useStore
} from 'vuex'
import {
    useRouter
} from 'vue-router'

const store = useStore()
const router = useRouter()

const currentRouteIndex = computed(() => store.state.currentRouteIndex)
const setRouter = () => {
    console.log('currentRoute.name', router.currentRoute.value.name)
    router.push(router.options.routes[currentRouteIndex.value - 1].path)
}
const routeProgress = (currentRouteIndex: number) => {
    let va = +(currentRouteIndex / 6).toFixed(1)
    // console.log('va', va, typeof va)
    return va * 100
}
const ifShow = computed(() => store.state.progressBarVisible)
console.log('ifShowProgress', ifShow.value)
const format = (percentage: number) => {
    // console.log(percentage, typeof percentage)
    switch (percentage) {
        case +(1 / 6).toFixed(1) * 100:
            return '1/6'

        case +(2 / 6).toFixed(1) * 100:
            return '2/6'

        case +(3 / 6).toFixed(1) * 100:
            return '3/6'

        case +(4 / 6).toFixed(1) * 100:
            return '4/6'

        case +(5 / 6).toFixed(1) * 100:
            return '5/6'

        case +(6 / 6).toFixed(1) * 100:
            return '6/6'
        default:
            return '0/6'
    }
}
</script>

<template>
<div class="progressHeader" :space="80" v-if="store.state.progressBarVisible">
    <el-row :gutter="0">
        <el-col :xs="1" :sm="4" :md="7" :lg="7" :xl="9">
            <div class="grid-content" />
        </el-col>
        <el-col :xs="6" :sm="4" :md="3" :lg="2" :xl="1">
            <el-button class="backBtn" @click="setRouter()" size="default" text>
                <div class="iconfont">&#xe60f;</div>
            </el-button>
        </el-col>
        <el-col :xs="16" :sm="12" :md="7" :lg="6" :xl="5">
            <el-progress class="progressBar" :percentage="routeProgress(currentRouteIndex)" color="#9cd54f" :format="format" />
        </el-col>
        <el-col :xs="1" :sm="4" :md="7" :lg="7" :xl="9">
            <div class="grid-content" />
        </el-col>

    </el-row>
</div>
</template>

<style lang="scss" scoped>
.backBtn {
    margin-left: .5rem;
    padding: 0.1rem;
}

.iconfont {
    font-size: 0.2rem;
}

.progressBar {
    padding-top: 0.1rem;
}

.progressHeader {
    margin-top: 0.5rem;
    // width: 350px;
}
</style>
