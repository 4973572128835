<template>
<div>
    <div class="title">请输入您的体重</div>
    <div class="centerDiv">
        <div class="inputGroup">
            <input type="text" required autocomplete="off" @keyup.enter="btnSubmit" v-model="weight" :oninput="handleChange" />
            <label for="name">千克</label>
        </div>
    </div>
    <!-- <el-input-number :min="25" :max="300" @change="handleChange" v-model="weight" size="large" /> -->
    <div class="continueBtn" v-if="showBtn">
        <el-row :gutter="0" class="backframe">
            <el-col :xs="6" :sm="7" :md="8" :lg="9" :xl="9">
                <div class="grid-content" />
            </el-col>
            <el-col :xs="12" :sm="10" :md="8" :lg="6" :xl="6">
                <div class="msgCal">
                    <el-alert title="身体质量指数(BMI)" center type="info" :closable="false" show-icon :description="bmiGet" />
                </div>
                <div class="continue_btn">
                    <el-button type="success" plain @click="btnSubmit">确认并继续</el-button>
                </div>
            </el-col>
            <el-col :xs="6" :sm="7" :md="8" :lg="9" :xl="9">
                <div class="grid-content" />
            </el-col>
        </el-row>
    </div>
</div>
</template>

<script lang="ts" setup>
import {
    ref,
    computed,
    ComputedRef
} from 'vue'
import {
    useStore
} from 'vuex'
import {
    useRouter
} from 'vue-router'
import {
    getBMR,
    getBMIStr,
    getBMI
} from '../composables/calcul'
const store = useStore()
const showBtn = ref(!!store.state.persona.weight)

const handleChange = (value: number) => {
    showBtn.value = true
}
store.commit('setCurrentRouteIndex', 4)
store.commit('setVisible', {
    type: 'backBtn',
    value: true
})
store.commit('setVisible', {
    type: 'progressBar',
    value: true
})
const router = useRouter()
const bmiGet = computed(() => {
    const bmRValue = getBMR(store.state.persona.gender, store.state.persona.height, weight.value, store.state.persona.age)
    const bmiValue = getBMI(store.state.persona.height, weight.value)
    const bmiStr = getBMIStr(bmiValue)
    return `您的BMI为${bmiValue.toFixed(1)}, ${bmiStr}`
})
const weightGet = computed(() => store.state.persona.weight)
const weight = ref(weightGet.value)
const setState = (value: number) => {
    store.commit('setPersona', {
        weight: value
    })
}
const btnSubmit = () => {
    setState(+weight.value)
    router.push('/sport')
}
</script>

<style lang="scss" scoped>
@import url('../styles/question.scss');

.input_widget {
    margin-top: 0.5rem;
}

.continueBtn {
    margin-top: 0.5rem;
}

.continue_btn {
    margin-top: 0.5rem;
}
</style>
