<template>
<div>
    <div class="title">请选择您的性别</div>
    <el-row :gutter="0" class="backframe">
        <el-col :xs="2" :sm="3" :md="7" :lg="8" :xl="9">
            <div class="grid-content" />
        </el-col>
        <el-col :xs="10" :sm="9" :md="5" :lg="4" :xl="3">
            <div class="col-content" @click="setGender('男')">
                <el-card :shadow="gender == '男' ? 'always' : 'hover'">
                    <div class="card-header">
                        <div class="iconfont">&#xe601;</div>
                    </div>
                    <div class="btnGender">男</div>
                </el-card>
            </div>
        </el-col>
        <el-col :xs="10" :sm="9" :md="5" :lg="4" :xl="3">
            <div class="col-content" @click="setGender('女')">
                <el-card :shadow="gender == '女' ? 'always' : 'hover'">
                    <div class="card-header">
                        <div class="iconfont">&#xe616;</div>
                    </div>
                    <div class="btnGender">女</div>
                </el-card>
            </div>
        </el-col>
        <el-col :xs="2" :sm="3" :md="7" :lg="8" :xl="9">
            <div class="grid-content" />
        </el-col>
    </el-row>
</div>
</template>

<script lang="ts" setup>
import {
    ref,
    computed
} from 'vue'
import {
    useStore
} from 'vuex'
import {
    useRouter
} from 'vue-router'
import {
    GlobalDataProps
} from "../composables/stores";
const store = useStore < GlobalDataProps > ()
store.commit('setCurrentRouteIndex', 1)
store.commit('setVisible', {
    value: true
})
const router = useRouter()
const gender = computed(() => store.state.persona.gender)
const setGender = (value: string) => {
    store.commit('setPersona', {
        gender: value
    })
    router.push('/age')
}
</script>

<style lang="scss" scoped>
@import url('../styles/question.scss');

.title_first {
    margin-top: 2%;
    font-size: 0.3rem;
    font-weight: bolder;
}

.title_sub {
    margin-top: 0.1rem;
    color: gray;
    font-size: 0.18rem;
}

.el-col {
    border-radius: 4px;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.backframe {
    margin-top: 0.4rem;
}

.col-content {
    margin: 0.1rem;
    // width: 2rem;
    // padding: 1rem;
    // background-color: lightblue;
}

.btnGender {
    // width: 1.5rem;
    // height: 1rem;
    // height: auto;
    font-size: 0.5rem;
}

.iconfont {
    font-size: 0.5rem;
}
</style>
