import { createApp } from 'vue'
import App from './App.vue'
import router from './composables/routers'
import store from './composables/stores'
import axios from './composables/request'
import '~/styles/index.scss'
import 'uno.css'
import 'normalize.css'
import '~/styles/iconfont.css'
import 'element-plus/theme-chalk/src/message.scss'
// import "~/styles/element/index.scss";

// import ElementPlus from "element-plus";
// import all element css, uncommented next line
// import "element-plus/dist/index.css";

// or use cdn, uncomment cdn link in `index.html`

const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')
