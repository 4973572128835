<script lang="ts" setup>
import {
    computed,
    // onMounted
} from 'vue';
import {
    useRouter
} from 'vue-router'
import {
    useStore
} from "vuex"
import {
    GlobalDataProps
} from "./composables/stores";
// import axios from "./composables/request"

const store = useStore < GlobalDataProps > ()
const router = useRouter()
const ifShowNaviBar = computed(() => router.currentRoute.value.name != "home" && router.currentRoute.value.name != "loading")
// const currentUser = computed(() => store.state.user)
// const token = computed(() => store.state.token)
// onMounted(() => {
//     if (!currentUser.value.isLogin && token.value) {
//         axios.defaults.headers.common.Authorization = `Bearer ${token}`
//     }
// })
</script>

<template>
<el-config-provider namespace="ep">
    <SanteHeader v-if="ifShowNaviBar" />
    <ProgressHeader />
    <div>
        <div>
            <RouterView></RouterView>
        </div>
    </div>
    <SanteFooter />
</el-config-provider>
</template>

<style>
#app {
    text-align: center;
    color: var(--ep-text-color-primary);
}
</style>
