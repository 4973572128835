<script lang="ts" setup>
import {
    computed
} from 'vue'
import {
    useRouter
} from 'vue-router'
import {
    useStore
} from 'vuex'
import {
    toggleDark
} from '~/composables'
import logoImg from '~/assets/logo-min.png'
import {
    ArrowDown
} from '@element-plus/icons-vue'
toggleDark(false)
const router = useRouter()
const store = useStore()
// const ifShowBackBtn = computed(
//     (): Boolean => store.state.backBtnVisible && store.state.neverSimu,
// )
// console.log(
//     'ifShowBackBtn:',
//     router.currentRoute.value.name,
//     ifShowBackBtn.value,
// )
const currentRouteIndex = computed(() => store.state.currentRouteIndex)
const setRouter = () => {
    console.log('currentRoute.name', router.currentRoute.value.name)
    if (!store.state.user.isLogin) {
        router.push(router.options.routes[currentRouteIndex.value - 1].path)
    }
}
const logout = () => {
    store.commit('logout')
    router.push('/')
}
</script>

<template>
<el-menu class="el-menu-demo" mode="horizontal">
    <!-- <span @click="setRouter()"
      ><el-menu-item index="1" v-if="ifShowBackBtn"><div class="iconfont">&#xe60f;</div></el-menu-item></span
    > -->
    <div>
        <el-menu-item index="1" @click="setRouter()"><img class="logo" :src="logoImg" /></el-menu-item>
    </div>
    <!-- <router-link to="/santeQuickView"><el-menu-item index="2">DemoAll</el-menu-item></router-link> -->
    <!-- <el-menu-item index="3" disabled>ChatGPT</el-menu-item> -->
    <!-- <el-menu-item index="4">订单</el-menu-item> -->
    <!-- <el-menu-item index="5" disabled>无限制对答</el-menu-item> -->
    <!-- <el-menu-item index="3" disabled  style='margin-left: auto;'>登陆</el-menu-item> -->
    <el-menu-item h="full" style="margin-left: auto" @click="toggleDark()">
        <!-- <button class="border-none w-full bg-transparent cursor-pointer" style="height: var(--ep-menu-item-height)">
            <i inline-flex i="dark:ep-moon ep-sunny" />
        </button> -->
    </el-menu-item>
    <!-- <el-menu-item index="2"><div class="flex-grow" /></el-menu-item> -->
    <router-link v-if="!store.state.user.isLogin" to="/login">
        <el-menu-item index="2">登陆</el-menu-item>
    </router-link>
    <!-- <router-link v-if="!store.state.user.isLogin" to="/sign"><el-menu-item index="4">注册</el-menu-item></router-link> -->
    <router-link v-if="store.state.user.isLogin" to="/client">
        <el-dropdown>
            <el-menu-item index="3">
                <span class="el-dropdown-link">{{ store.state.user.tel
            }}
                    <el-icon class="el-icon--right">
                        <arrow-down />
                    </el-icon>
                </span> </el-menu-item><template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="router.push('/client')">我的空间</el-dropdown-item>
                    <el-dropdown-item @click="logout">登出</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </router-link>
</el-menu>
</template>

<style lang="scss" scoped>
.flex-grow {
    margin-left: auto 0;
}

.logo {
    width: 0.4rem;
}

.iconfont {
    font-size: 0.16rem;
}

.el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
}

a {
    text-decoration: none;
}
</style>
