/**
 * Mifflin-St Jeor Equations（MSJE）公式
 * @param gender
 * @param height
 * @param weight
 * @param age
 * @returns kcal/day
 */
export const getBMR = (gender: string, height: number, weight: number, age: number) => {
  if (gender === '男') {
    const bmrValue = 10 * weight + 6.25 * height - 5 * age + 5
    return bmrValue
  } else {
    const bmrValue = 10 * weight + 6.25 * height - 5 * age - 161
    return bmrValue
  }
}

export const getBMI = (height: number, weight: number) => {
  const bmrValue = weight / ((height / 100) * (height / 100))
  return bmrValue
}

export const getBMIStr = (bmiValue: number) => {
  if (bmiValue < 18.5) return '低于正常体重'
  else if (bmiValue < 25) return '正常体重'
  else if (bmiValue < 30) return '超重'
  else if (bmiValue < 35) return '一类肥胖'
  else if (bmiValue < 40) return '二类肥胖'
  else return '三类肥胖'
}

/**
 *
 * @param bmrValue
 * @param level:
 * 1 久坐, 办公室的工作, 没什么运动
 * 2 轻量活动 1-2 次每周
 * 3 中等强度活动 2-3 次每周
 * 4 重等强度活动 4-5 次每周
 * 5 体力活 6-7 次每周
 * 6 专业运动员
 */
export const getTDEE = (bmrValue: number, level: number) => {
  var tdeeVal = 1.1
  switch (level) {
    case 1:
      tdeeVal = bmrValue * 1.2
      break
    case 2:
      tdeeVal = bmrValue * 1.375
      break
    case 3:
      tdeeVal = bmrValue * 1.55
      break
    case 4:
      tdeeVal = bmrValue * 1.725
      break
    case 5:
      tdeeVal = bmrValue * 1.9
      break
    case 6:
      tdeeVal = bmrValue * 2.4
      break
    default:
      tdeeVal = bmrValue * 1.55
  }
  return +tdeeVal.toFixed(1)
}
/**
 *
 * @param level
 * @returns
 * 1 久坐, 办公室的工作, 没什么运动
 * 2 轻量活动 1-2 次每周
 * 3 中等强度活动 2-3 次每周
 * 4 重等强度活动 4-5 次每周
 * 5 体力活 6-7 次每周
 * 6 专业运动员
 */
export const getLevelStr = (level: number) => {
  switch (level) {
    case 1:
      return '1: 久坐, 办公室的工作, 没什么运动'
    case 2:
      return '2: 轻量活动 1-2 次每周'
    case 3:
      return '3: 中等强度活动 2-3 次每周'
    case 4:
      return '4: 重等强度活动 4-5 次每周'
    case 5:
      return '5: 体力活 6-7 次每周'
    case 6:
      return '6: 专业运动员'
    default:
      return '3: 中等强度活动 2-3 次每周'
  }
}

export const getTargetKcal = (tdee: number, duration: number, loseWeight: number) => {
  var kcal = tdee - (7700 * (loseWeight / 2)) / (duration * 30)
  console.log(tdee, duration, loseWeight, kcal)
  return +kcal.toFixed(1)
}

export const getAllCalculStr = (dataRecette: any) => {
  return `# 基本数据\n您的年龄为${dataRecette.age},${dataRecette.gender},身高${dataRecette.height},体重${dataRecette.weight}kg。
  \n根据您提供的信息，您的基础代谢率（BMR）为约\`${dataRecette.bmrValue}\`卡路里。
  要计算您的总能量消耗量（TDEE），我们需要将您的BMR乘以您的活动系数。您活动等级是\`${getLevelStr(
    dataRecette.sportLevel,
  )}\`，因此，您的总能量消耗量（TDEE）约为\`${dataRecette.tdeeValue}\`卡路里/天。这意味着您每天需要摄入大约\`${
    dataRecette.tdeeValue
  }\`卡路里的热量来维持您现有的体重。
  \n请注意，这只是一个大致的估计，您的实际需求可能因个人因素，如生活方式，饮食，以及您的健康状况等因素而有所不同。
  \n根据您的目标计划: 在${dataRecette.duration}个月内减重${dataRecette.loseWeight}斤。为了达到您想要的减重效果，每日摄入卡路里的最多为\`${
    dataRecette.kcalTargetValue
  }\`。
  \n# 健身餐\n以下是以表格的形式为您定制的一周减肥饮食计划, 包括早餐,午餐和晚餐，让您每日摄入卡路里总量控制在\`${
    dataRecette.kcalTargetValue
  }\`卡路里以下，并尽可能接近该值。同时尽可能贴合我们中国人的口味.
  `
}

export const getEndStr = () => `
\n# 提示
\n\n上面的饮食计划旨在提供低热量但营养丰富的饮食选择，帮助控制每日的总卡路里摄入，以便于减肥。以下是一些关于饮食计划的说明和建议：

\n- 每日摄入的总卡路里量基于一般情况下的成年人的需要，实际需求可能因个体差异、身体活动水平和代谢率而异。因此，如有需要，请咨询医生或营养师获得个性化的建议。
\n- 早餐是一天中最重要的餐点，所以建议不要忽略。其中燕麦粥和麦片饼干等都是营养丰富、易于消化的选择。配合牛奶或豆浆可以增加蛋白质和钙的摄入。
午餐和晚餐都包含了蛋白质、蔬菜和全谷类等多种营养素。每顿餐都控制在半碗糙米饭的份量，可以控制总体碳水化合物的摄入。汤和饭一起吃可以使饱腹感更强，减少对高脂肪和高热量食物的需求。
\n- 餐食中的蛋白质来源于鸡肉、鱼肉、虾仁等食物，这些食物具有较低的脂肪含量和高的蛋白质含量。蛋白质有助于维持肌肉质量，促进新陈代谢，并使人保持饱腹感更长的时间。
\n- 每天吃足量的蔬菜是维持身体健康的关键。餐食中的蔬菜来自番茄、胡萝卜、豆角、蘑菇、韭菜、青椒、菠菜等食物，提供了多种维生素和矿物质。
餐食中的烹饪方式尽可能采用清炒、蒸、烤等低脂肪、低卡路里的方法，避免高脂肪的烹饪方法如炸、煎等，以减少额外的脂肪和卡路里的摄入。
\n- 饮食计划中的餐食可以根据个人口味和喜好进行调整，但要注意卡路里的总量和每个餐食中的碳水化合物、脂肪和蛋白质的比例。适量增加膳食纤维的摄入可以增加饱腹感，降低饮食中的热量摄入。
\n- 如果感觉饥饿或需要一些零食，可以选择一些低卡路里、高营养的水果或坚果作为补充。避免食用高热量、高脂肪、高糖分的零食，如糖果、巧克力和油炸食品等。
\n- 除了饮食，适当的运动也是减肥的关键。根据身体状况和健康状况，选择适当的运动方式和强度，每周保持150分钟的中等强度有氧运动可以帮助减少体重和保持健康。
\n- 最后，如果您在减肥过程中遇到问题或担心健康问题，请及时咨询专业医生或营养师的建议，以获得更好的指导和支持。
`
