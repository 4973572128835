<template>
<span>
    {{ timeStr }}
</span>
</template>

<script lang="ts" setup>
import {
    ref,
    onMounted,
    onBeforeUnmount
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRouter
} from "vue-router";

const store = useStore()
const router = useRouter()
const timeStr = ref('');
console.log(store.state.countdown)
const timeDifference = ref(store.state.countdown);

let countdownIntervalList: any[] = []
const timerCd = () => {
    const millisecondsInOneSecond = 1000;
    const millisecondsInOneMinute = millisecondsInOneSecond * 60;
    const millisecondsInOneHour = millisecondsInOneMinute * 60;
    const millisecondsInOneDay = millisecondsInOneHour * 24;
    const differenceInDays = timeDifference.value / millisecondsInOneDay;
    const remainderDifferenceInHours = (timeDifference.value % millisecondsInOneDay) / millisecondsInOneHour;
    const remainderDifferenceInMinutes = (timeDifference.value % millisecondsInOneHour) / millisecondsInOneMinute;
    const remainderDifferenceInSeconds = (timeDifference.value % millisecondsInOneMinute) / millisecondsInOneSecond;
    const remainingDays = Math.floor(differenceInDays);
    const remainingHours = Math.floor(remainderDifferenceInHours);
    const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
    const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
    timeStr.value = remainingMinutes + " 分钟 " + remainingSeconds + " 秒";
    // timeStr.value = remainingDays + " Days " + ": " + remainingHours + " Hours " + ": " + remainingMinutes + " Minutes " + ": " + remainingSeconds + " Seconds";
    timeDifference.value = timeDifference.value - 1000;
    store.commit("setCountdown", timeDifference.value)
}

onMounted(() => {
    countdownIntervalList = []
    let countdownInterval = setInterval(() => {
        timerCd();
        if (timeDifference.value < 0) {
            // store.commit("logout")
            store.commit("setCountdown", 15 * 60 * 1000)
            router.push("/client")
        }
    }, 1000);
    countdownIntervalList.push(countdownInterval);

})

onBeforeUnmount(() => {
    countdownIntervalList.forEach((item, index) => {
        clearInterval(item)
    })
    countdownIntervalList = []

})
</script>

<style lang="scss" scoped>
</style>
