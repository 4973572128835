<template>
<div>
    <div class="title">请输入您的健身计划</div>
    <div class="title_sub">您计划多久时间</div>
    <div class="centerDiv">
        <div class="inputGroup">
            <input type="text" required autocomplete="off" v-model="duration" :oninput="handleChange" />
            <label for="name">月</label>
        </div>
    </div>
    <!-- <el-input-number :min="1" :max="12" @change="handleChange" v-model="duration" size="large" /> -->
    <div class="title_sub">您计划减重多少</div>
    <div class="centerDiv">
        <div class="inputGroup">
            <input type="text" required autocomplete="off" v-model="loseWeight" @keyup.enter="btnSubmit" :oninput="handleChange" />
            <label for="name">公斤</label>
        </div>
    </div>
    <!-- <el-input-number :min="1" :max="20" @change="handleChange" v-model="loseWeight" size="large" /> -->
    <div class="continueBtn" v-if="showBtn">
        <div class="centerDiv ">
            <div class="alert_info">
                <el-alert title="每日总能量消耗(TDEE)" center type="info" :closable="false" show-icon :description="`${tdeeGet}. ${targetKcalGet}`" />
            </div>
        </div>
        <div class="centerDiv">
            <el-button type="success" plain @click="btnSubmit">确认并继续</el-button>
        </div>
        <!-- <el-row :gutter="0" class="alert_info">
            <el-col :xs="6" :sm="7" :md="8" :lg="9" :xl="9">
                <div class="grid-content" />
            </el-col>
            <el-col :xs="12" :sm="10" :md="8" :lg="6" :xl="6">
                <div>
                </div>
                <div class="msg_cal">
                    <el-button type="success" plain @click="btnSubmit">确认并继续</el-button>
                </div>
            </el-col>
            <el-col :xs="6" :sm="7" :md="8" :lg="9" :xl="9">
                <div class="grid-content" />
            </el-col>
        </el-row> -->
    </div>
</div>
</template>

<script lang="ts" setup>
import {
    ref,
    computed,
    ComputedRef
} from 'vue'
import {
    useStore
} from 'vuex'
import {
    useRouter
} from 'vue-router'
import {
    getBMR,
    getBMIStr,
    getBMI,
    getTDEE,
    getTargetKcal
} from '../composables/calcul'
import {
    showMessage
} from '~/composables/message';
const store = useStore()
const router = useRouter()
store.commit('setCurrentRouteIndex', 6)
store.commit('setVisible', {
    type: 'backBtn',
    value: true
})
store.commit('setVisible', {
    type: 'progressBar',
    value: true
})
const showBtn = ref(!!store.state.persona.loseWeight)
const tdeeValue = ref(0)
const bmrValue = ref(0)
const targetKcal = ref(0)
const durationGet = computed(() => store.state.persona.duration)
const loseWeightGet = computed(() => store.state.persona.loseWeight)
const duration = ref(durationGet.value)
const loseWeight = ref(loseWeightGet.value)

const handleChange = (value: number) => {
    showBtn.value = true
}
const tdeeGet = computed(() => {
    bmrValue.value = getBMR(store.state.persona.gender, store.state.persona.height, store.state.persona.weight, store.state.persona.age)
    tdeeValue.value = getTDEE(bmrValue.value, store.state.persona.sportLevel)
    return `您的每日总能量消耗为${tdeeValue.value}kcal, 包括基础代谢、活动量、吃东西所消耗的热量. 其中基础代谢为${bmrValue.value}kcal`
})
const targetKcalGet = computed(() => {
    targetKcal.value = getTargetKcal(+tdeeValue.value, duration.value, loseWeight.value)
    return `为达到目标您的每日总能量消耗应该为${targetKcal.value}kcal`
})
const btnSubmit = () => {

    if (+loseWeight.value / +duration.value > 2) {
        showMessage("为了您的身体健康，减重请循序渐进，平均每个月不要超过2公斤", "warning")
    } else {
        store.commit('setPersona', {
            duration: +duration.value,
            bmrValue: +bmrValue.value,
            loseWeight: +loseWeight.value,
            tdeeValue: +tdeeValue.value,
            kcalTargetValue: +targetKcal.value
        })
        router.push('/loading')
    }
}
</script>

<style lang="scss" scoped>
@import url('../styles/question.scss');

.continueBtn {
    margin-top: 0.2rem;
}

.title_sub {
    margin-top: 0.4rem;
    color: gray;
    font-size: 0.18rem;
}

.alert_info {
    width: 3.7rem;
}

.target_btn {
    margin-top: 0.2rem;
}
</style>
