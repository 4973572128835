<template>
<div>
    <div class="loader">
        <el-progress type="dashboard" :percentage="percentage" :width="progressWidth" :stroke-width="progressStroke" :color="colors" />
    </div>
    <div id=container>
        <div id=flip>
            <div>
                <div>您的智能食谱生成完毕！</div>
            </div>
            <div>
                <div>选择合适的食谱</div>
            </div>
            <div>
                <div>推算每餐的卡路里</div>
            </div>
            <div>
                <div>计算饮食状况</div>
            </div>
            <div>
                <div>处理输入的数据</div>
            </div>
        </div>
    </div>
    <!-- <el-row :gutter="0">
        <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="grid-content" />
        </el-col>
        <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">

        </el-col>
        <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="grid-content" />
        </el-col>
    </el-row> -->
</div>
</template>

<script lang="ts" setup>
import {
    onMounted,
    ref,
    onBeforeUnmount,
    computed
} from 'vue';
import {
    useRouter
} from 'vue-router';
import {
    useStore
} from 'vuex'
import {
    post
} from '~/composables/request';
import {
    GlobalDataProps
} from '../composables/stores'
const store = useStore < GlobalDataProps > ()
const router = useRouter()
const progressWidth = 200
const progressStroke = 10
const percentage = ref(0)
const colors = [{
        color: '#f56c6c',
        percentage: 20
    },
    {
        color: '#e6a23c',
        percentage: 40
    },
    {
        color: '#5cb87a',
        percentage: 60
    },
    {
        color: '#1989fa',
        percentage: 80
    },
    {
        color: '#6f7ad3',
        percentage: 100
    },
]
const isLogin = computed(() => store.state.user.isLogin)
store.commit('setVisible', {
    type: 'progressBar',
    value: false
})
let countdownIntervalList: any[] = []
onMounted(() => {
    countdownIntervalList = []
    let countdownInterval = setInterval(() => {
        percentage.value = percentage.value + 25
        // percentage.value = (percentage.value % 100) + 25
        if (percentage.value > 100) {
            if (isLogin.value) {
                post(store.state.persona).then(result => {
                    console.log('post simu res')
                    console.log('result report: ', result)
                    store.commit('setReport', result.data.report)
                })
                store.commit("setPersonaComplet", true)
                post(store.state.user, "/user-update").then(result => {
                    console.log('user update')
                    console.log('result report: ', result)
                })
                router.push('/client')
            } else {
                router.push("/landing")
            }

        }
    }, 2000)
    countdownIntervalList.push(countdownInterval);
})
onBeforeUnmount(() => {
    countdownIntervalList.forEach((item, index) => {
        clearInterval(item)
    })
    countdownIntervalList = []

})
</script>

<style lang="scss" scoped>
.loader {
    margin-top: .5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 3rem;
}

#container {
    color: #999;
    text-transform: uppercase;
    font-size: .3rem;
    font-weight: bold;
    padding-top: .1rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#flip {
    height: 3.5rem;
    overflow: hidden;
}

#flip>div>div {
    color: #fff;
    padding: 4px 12px;
    height: 45px;
    margin-bottom: 45px;
    display: inline-block;
}

#flip div:first-child {
    animation: show 10s linear infinite;
}

#flip div div {
    background: #42c58a;
}

#flip div:first-child div {
    background: #4ec7f3;
}

#flip div:last-child div {
    background: #DC143C;
}

@keyframes show {
    0% {
        margin-top: -540px;
    }

    8% {
        margin-top: -360px;
    }

    15% {
        margin-top: -360px;
    }

    18% {
        margin-top: -360px;
    }

    20% {
        margin-top: -270px;
    }

    38% {
        margin-top: -270px;
    }

    40% {
        margin-top: -180px;
    }

    58% {
        margin-top: -180px;
    }

    60% {
        margin-top: -90px;
    }

    80% {
        margin-top: -90px;
    }

    82% {
        margin-top: 0px;
    }

    90% {
        margin-top: 0px;
    }

    100% {
        margin-top: 0px;
    }
}
</style>
