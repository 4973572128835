<template>
<div>

    <div class="centerDiv">
        <div class="title">您的智能餐谱已经准备就绪！</div>
    </div>

    <BasicUnitView />

    <div class="centerDiv">
        <button class="buttonCool buttonCool-landing" @click="router.push('/sign')">立刻获取您的智能健康食谱</button>
    </div>
</div>
</template>

<script lang="ts" setup>
import BasicUnitView from "../components/layouts/BasicUnitView.vue";
import {
    useRouter
} from 'vue-router';
import {
    useStore
} from 'vuex';
const router = useRouter()
const store = useStore()
store.commit('setVisible', {
    type: 'progressBar',
    value: false
})
</script>

<style lang="scss" scoped>
@import url("../styles/question.scss");

.buttonCool-landing {
    --color: #63af76;
    margin: .5rem .1rem 0 .1rem;
}

.helpContent {
    position: absolute;
    z-index: 2;
    background-color: #fff;
    width: 85%;
    height: 90%;
    padding-left: 2%;
    padding-right: 2%;
    font-size: .10rem;

    &__body {
        padding-top: 30%;
    }

    &__closeBtn {
        color: #9cd54f;
        position: absolute;
        top: 1%;
        right: 9%;
        border: 1px solid #9cd54f;
        border-radius: .03rem;
        padding: 2%
    }
}

.help {
    position: absolute;
    top: 10%;
    right: 10%;
    font-size: .16rem;
    color: #9cd54f;
}

.card {
    margin: .1rem;
    width: 1.2rem;

    &__title {
        font-size: 0.2rem;
        font-weight: bold;
        color: #9cd54f;
    }

    &__subtitle {
        font-size: 0.1rem;
    }

    &--big {
        position: relative;
        text-align: left;
        margin: .1rem;
        width: 2.7rem;
        height: 2.7rem;
    }
}

.marginTop1Rem {
    margin-top: .1rem;
}

.marginTop2Rem {
    margin-top: .2rem;
}
</style>
