import { ElMessage } from 'element-plus'

export const showMessage = (msg: string, typeMsg: 'success' | 'warning' | 'info' | 'error') => {
  let durationMsg = 5000
  if (typeMsg == 'error') {
    durationMsg = 8000
  }
  return ElMessage({
    showClose: true,
    message: msg,
    type: typeMsg,
    duration: durationMsg,
  })
}
