import type { FormInstance } from 'element-plus'
import { reactive, ref } from 'vue'

import store from './stores'

export const ruleFormRef = ref<FormInstance>()
export const ruleForm = reactive(
  store.state.stage == 'dev'
    ? {
        tel: '18898789879',
        pass: 'jhghhj',
        checkPass: 'jhghhj',
        code: '6544',
        promo: '',
      }
    : {
        tel: '',
        pass: '',
        checkPass: '',
        code: '',
        promo: '',
      }
)
export const validatePass = (rule: any, value: any, callback: any) => {
  if (value === '') {
    callback(new Error('请输入密码'))
  } else {
    if (ruleForm.checkPass !== '') {
      if (!ruleFormRef.value) return
      ruleFormRef.value.validateField('checkPass', () => null)
    }
    callback()
  }
}
export const validatePass2 = (rule: any, value: any, callback: any) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== ruleForm.pass) {
    callback(new Error('两次输入不一致!'))
  } else {
    callback()
  }
}

export const validateTel = (rule: any, value: any, callback: any) => {
  if (value === '') {
    callback(new Error('请输入手机号码'))
  } else if (value.length !== 11) {
    callback(new Error('手机号长度必须为11位'))
  } else {
    callback()
  }
}
export const validateCode = (rule: any, value: any, callback: any) => {
  if (value === '') {
    callback(new Error('请输入验证码'))
  }
  // if (value.length !== 4) {
  //     callback(new Error('验证码为4位'))
  // } else
  else {
    callback()
  }
}
