<template>
<div>
    <div class="title">以下是通过ChatGPT为您定制的健身餐</div>
    <el-row :gutter="0" class="backframe">
        <el-col :xs="2" :sm="3" :md="4" :lg="4" :xl="5">
            <div class="grid-content" />
        </el-col>
        <el-col :xs="20" :sm="18" :md="16" :lg="16" :xl="14">
            <ReportBasicView />
            <br />
            <div class="tableReport" v-loading="tableLoading">
                <el-table :data="reportData" stripe border style="width: 100%">
                    <el-table-column fixed prop="date" label="日期" min-width="70" />
                    <el-table-column prop="breakfast" label="早餐" min-width="188" />
                    <el-table-column prop="launch" label="午餐" min-width="188" />
                    <el-table-column prop="supper" label="晚餐" min-width="188" />
                    <el-table-column prop="kcal" label="卡路里" min-width="80" />
                </el-table>
            </div>
            <ReportEndView />
        </el-col>
        <el-col :xs="2" :sm="3" :md="4" :lg="4" :xl="5">
            <div class="grid-content" />
        </el-col>
    </el-row>
</div>
</template>

<script lang="ts" setup>
import ReportBasicView from './ReportBasicView.vue'
import ReportEndView from './ReportEndView.vue'
import {
    ref,
    computed,
    ComputedRef,
    reactive
} from 'vue'
import {
    useStore
} from 'vuex'
import axios, {
    get
} from '../composables/request'
import {
    getAllCalculStr,
    getEndStr
} from '../composables/calcul'
import router from '~/composables/routers'
const store = useStore()
store.commit('setVisible', {
    value: false
})
const tableLoading = ref(true)
const reportStrGet = computed(() => getAllCalculStr({
    ...store.state.persona
}))
let reportData = reactive([])
const reportDataApi = async () => {
    // axios.defaults.headers.common.Authorization = store.state.token

    try {
        const result = await get()
        console.log(result)
        if (result.code === 10004) {
            store.commit("setPersonaComplet", false)
            router.push("/gender")
            // toast(result?.msg)
            console.log(result)
        }
        // toast("")
        tableLoading.value = false
        console.log('get result:')
        console.log(result)
        const {
            age,
            bmrValue,
            duration,
            gender,
            height,
            id,
            kcalTargetValue,
            loseWeight,
            report,
            sportLevel,
            tdeeValue,
            weight
        } = result.data
        reportData = report
        store.commit('setReport', report)
        store.commit('setPersona', {
            age: age,
            bmrValue: bmrValue,
            duration: duration,
            gender: gender,
            height: height,
            kcalTargetValue: kcalTargetValue,
            loseWeight: loseWeight,
            sportLevel: sportLevel,
            tdeeValue: tdeeValue,
            weight: weight,
        })
    } catch (e) {
        // toast(e)
        console.log('get result e:')
        console.log(e)
    }
}
reportDataApi()
</script>

<style lang="scss" scoped>
@import url('../styles/question.scss');

.report_recap {
    text-align: left;
}

.continueBtn {
    margin-top: 0.5rem;
}

.title-sub {
    margin-top: 0.5rem;
    color: gray;
    font-size: 0.18rem;
}

.target_btn {
    margin-top: 0.2rem;
}
</style>
