import { createRouter, createWebHistory } from 'vue-router'
import store from './stores'
import axios from './request'
import HomeView from '../components/HomeView.vue'
import GenderView from '../components/GenderView.vue'
import AgeView from '../components/AgeView.vue'
import HeightView from '../components/HeightView.vue'
import WeightView from '../components/WeightView.vue'
import SportView from '../components/SportView.vue'
import TargetView from '../components/TargetView.vue'
import LoadingView from '../components/LoadingView.vue'
import LandingView from '../components/LandingView.vue'
import ReportView from '../components/ReportView.vue'
import LoginView from '../components/LoginView.vue'
import SignView from '../components/SignView.vue'
import ClientView from '../components/ClientView.vue'
import BasicView from '../components/BasicView.vue'
import PayView from '../components/PayView.vue'
// import SanteQuickView from '../components/SanteQuickView.vue'

const routerHistory = createWebHistory()
const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        redirectAlreadyLogin: true,
      },
    },
    {
      path: '/gender',
      name: 'gender',
      component: GenderView,
      meta: {
        notComplet: true,
      },
    },
    {
      path: '/age',
      name: 'age',
      component: AgeView,
      meta: {
        notComplet: true,
      },
    },
    {
      path: '/height',
      name: 'height',
      component: HeightView,
      meta: {
        notComplet: true,
      },
    },
    {
      path: '/weight',
      name: 'weight',
      component: WeightView,
      meta: {
        notComplet: true,
      },
    },
    {
      path: '/sport',
      name: 'sport',
      component: SportView,
      meta: {
        notComplet: true,
      },
    },
    {
      path: '/target',
      name: 'target',
      component: TargetView,
      meta: {
        notComplet: true,
      },
    },
    {
      path: '/landing',
      name: 'landing',
      component: LandingView,
      meta: {
        notComplet: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: {
        redirectAlreadyLogin: true,
      },
    },
    {
      path: '/sign',
      name: 'sign',
      component: SignView,
      meta: {
        redirectAlreadyLogin: true,
      },
    },
    {
      path: '/loading',
      name: 'loading',
      component: LoadingView,
    },
    {
      path: '/client',
      name: 'client',
      component: ClientView,
      meta: {
        requiredLogin: true,
      },
    },
    {
      path: '/basic',
      name: 'basic',
      component: BasicView,
      meta: {
        requiredLogin: true,
        requiredComplet: true,
      },
    },
    {
      path: '/pay',
      name: 'pay',
      component: PayView,
      meta: {
        requiredLogin: true,
      },
    },
    {
      path: '/report',
      name: 'report',
      component: ReportView,
      meta: {
        requiredLogin: true,
        requiredComplet: true,
        requiredPaid: true,
      },
    },
    // {
    //   path: '/santeQuickView',
    //   name: 'santeQuickView',
    //   component: SanteQuickView,
    // },
  ],
})

router.beforeEach((to, from, next) => {
  const { user, token } = store.state
  const { requiredLogin, redirectAlreadyLogin, notComplet, requiredComplet, requiredPaid } = to.meta
  console.log('router', to, from)
  if (!user.isLogin) {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      store
        .dispatch('fetchCurrentUser')
        .then(() => {
          if (redirectAlreadyLogin) {
            next('/client')
          } else if (requiredComplet) {
            if (user.isComplet) {
              if (requiredPaid) {
                if (user.isPaid) {
                  next()
                } else {
                  next('/pay')
                }
              }
            } else {
              next('/gender')
            }
          } else {
            next()
          }
        })
        .catch((e) => {
          console.error(e)
          store.commit('logout')
          next('/login')
        })
    } else {
      if (requiredLogin) {
        next('/login')
      } else {
        next()
      }
    }
  } else {
    if (redirectAlreadyLogin) {
      next('/client')
    } else {
      next()
    }
  }

  // if (requiredLogin) {
  //   if (!user.isLogin) {
  //     next('/login')
  //   }
  // } else if (redirectAlreadyLogin && user.isLogin) {
  //   next('/report')
  // } else {
  //   next()
  // }
})

export default router
