<template>
<div>
    <div class="title">请输入您的身高</div>
    <div class="centerDiv">
        <div class="inputGroup">
            <input type="text" required autocomplete="off" v-model="height" @keyup.enter="btnSubmit" :oninput="handleChange" />
            <label for="name">厘米</label>
        </div>
    </div>
    <!-- <el-input-number :min="90" :max="243" @change="handleChange" v-model="height" size="large" /> -->
    <div class="continueBtn" v-if="showBtn">
        <el-button type="success" plain @click="btnSubmit">确认并继续</el-button>
    </div>
</div>
</template>

<script lang="ts" setup>
import {
    ref,
    computed,
    ComputedRef
} from 'vue'
import {
    useStore
} from 'vuex'
import {
    useRouter
} from 'vue-router'
const store = useStore()
const showBtn = ref(!!store.state.persona.height)
const handleChange = (value: number) => {
    showBtn.value = true
}
store.commit('setCurrentRouteIndex', 3)
store.commit('setVisible', {
    type: 'backBtn',
    value: true
})
store.commit('setVisible', {
    type: 'progressBar',
    value: true
})
const router = useRouter()
const heightGet = computed(() => store.state.persona.height)
const height = ref(heightGet.value)
const setState = (value: number) => {
    store.commit('setPersona', {
        height: value
    })
}
const btnSubmit = () => {
    setState(+height.value)
    router.push('/weight')
}
</script>

<style lang="scss" scoped>
@import url('../styles/question.scss');

.input_widget {
    margin-top: .5rem;
    ;
}

.continueBtn {
    margin-top: .5rem;
}
</style>
