<template>
<div>

    <div class="prez">
        <div class="prez__mask">
            <div class="prez__title">
                此优惠有效期还剩
                <CountDown />
            </div>
            <div class="prez__content">

                <div :class="classSelectedObj(1)" @click="selected(1)">
                    <span class="iconfont prez__desc__icon"> &#xe60c; </span>
                    <span class="prez__desc__text">2个月</span>
                    <span class="prez__desc__text highlight">每周4.5元</span>
                </div>
                <div :class="classSelectedObj(2)" @click="selected(2)">
                    <span class="iconfont prez__desc__icon"> &#xe60c; </span>
                    <span class="prez__desc__text">1个月</span>
                    <span class="prez__desc__text highlight">每周5元</span>
                </div>
                <div :class="classSelectedObj(3)" @click="selected(3)">
                    <span class="iconfont prez__desc__icon"> &#xe60c; </span>
                    <span class="prez__desc__text">3个月</span>
                    <span class="prez__desc__text highlight">每周4.17元</span>
                </div>
            </div>

            <div class="prez__mask__pricing highlight">
                <div>总价 {{totalPrice}}</div>
                <br />
                <button class="buttonCool buttonCool-start iconfont highlight" @click="router.push('/report')">微信支付</button>
                <!-- <button class="buttonCool buttonCool-start iconfont highlight" @click="router.push('/report')">支付宝</button> -->
            </div>

        </div>
    </div>
</div>
</template>

<script lang="ts" setup>
import CountDown from "../components/layouts/CountDown.vue";
import {
    useStore
} from 'vuex'
import {
    useRouter
} from 'vue-router'
import {
    reactive,
    ref
} from 'vue';
const router = useRouter()
const store = useStore()
store.commit('setVisible', {
    value: false
})
const optionSelectedRef = ref(0)
const totalPrice = ref(0)
const pushLink = (link: string) => {
    if (link == "report") {
        router.push("/report")
    }
    if (link == "basic") {
        router.push("/basic")
    }
}
const classSelectedObj = (value: number) => {
    return {
        "glassCard": true,
        "prez__desc": true,
        "prez__desc__focus": optionSelectedRef.value == value
    }

}
const selected = (optionSelected: number) => {
    optionSelectedRef.value = optionSelected
    if (optionSelected == 1) {
        totalPrice.value = 20
    } else if (optionSelected == 2) {
        totalPrice.value = 36
    }
    if (optionSelected == 3) {
        totalPrice.value = 50
    }
}
</script>

<style lang="scss" scoped>
@import url("../styles/question.scss");

.prez {
    background: url('~/assets/bg_bk.jpg') 50% 0 no-repeat fixed;
    background-size: cover;
    height: 6rem;
    z-index: 0;

    &__title {
        padding-top: .3rem;
        color: #ddd;
        font-size: .3rem;
    }

    &__mask {
        height: 100%;
        z-index: 1;
        width: 100%;
        position: relative;

        &__pricing {
            position: absolute;
            left: 50%;
            bottom: 2%;
            transform: translate(-50%, -50%);
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;

    }

    &__desc {
        margin-top: .5rem;
        width: 2rem;
        padding: .2rem;
        margin-left: .2rem;
        margin-right: .2rem;
        color: #000000;
        font-size: .2rem;
        font-weight: bold;
        cursor: pointer;
        transition: transform 250ms;

        &__icon {
            flex: 1;
            text-align: left;
            max-width: .3rem;
        }

        &__text {
            flex: 2;
            text-align: right;
        }
    }

    &__desc:hover {
        transform: translateY(-10px);
    }

    &__desc__focus {
        outline: 4px solid #d0f637;
    }

}
</style>
