<template>
<div class="centerDiv">
    <el-card class="card" shadow="hover">
        <div class="card__title">{{store.state.persona.age}}</div>
        <div class="card__subtitle">年龄</div>
    </el-card>
    <el-card class="card" shadow="hover">
        <div class="card__title">{{store.state.persona.height}}</div>
        <div class="card__subtitle">身高(厘米)</div>
    </el-card>
    <el-card class="card" shadow="hover">
        <div class="card__title">{{store.state.persona.weight}}</div>
        <div class="card__subtitle">体重(千克)</div>
    </el-card>
</div>
<div class="centerDiv">
    <el-card class="card--big" shadow="hover">
        <div class="helpContent" v-if="ifShowHelpLoseWeight">
            <div class="helpContent__closeBtn" @click="ifShowHelpLoseWeight=false">x</div>
            <div class="helpContent__body">
                <p>减脂塑身并非一个线性过程。</p>
                <p>一开始，身体脂肪和体重可能会快速降低，之后，这个过程会随着时间的推移而渐趋缓慢。</p>
                <p>这是完全正常的，因此，不要泄气！持续的快速减脂并不是好事！请保持耐心并且严格地遵循您的饮食计划！</p>
            </div>
        </div>
        <div class="card__title">您的体重变化</div>
        <div class="help iconfont" @click="ifShowHelpLoseWeight=true">&#xe698;</div>
        <div class="card__subtitle marginTop2Rem">{{store.state.persona.loseWeight}}个月内体重预计变化</div>
        <div class="card__title iconfont marginTop1Rem">&#xe64c; {{store.state.persona.weight-store.state.persona.loseWeight}}</div>
        <div class="marginTop2Rem">
            <loseWeightSvg />
        </div>
    </el-card>
    <el-card class="card--big" shadow="hover">
        <div class="helpContent" v-if="ifShowHelpShape">
            <div class="helpContent__closeBtn" @click="ifShowHelpShape=false">x</div>
            <div class="helpContent__body">
                <p>您的身材结构将得到优化，并且实现全身减重。</p>
                <p>但老实说，定点局部减重是不可行的，塑身的过程并非如此。</p>
                <p>女性通常最后开始下半身减脂，而男性则从腹部开始减脂。</p>
                <p>不同身体部位的脂肪减少率并不相同，这是由基因、激素等决定的。</p>
            </div>
        </div>
        <div class="card__title">您的体型变化</div>
        <div class="help iconfont" @click="ifShowHelpShape=true">&#xe698;</div>
        <div class="marginTop2Rem">
            <maleShapeSvg v-if="store.state.persona.gender=='男'" />
            <femaleShapeSvg v-if="store.state.persona.gender!='男'" />
        </div>
        <div class="card__subtitle marginTop2Rem centerDiv">基于其他相同代谢形态的成功案例</div>
    </el-card>

</div>
</template>

<script lang="ts" setup>
import {
    ref
} from "vue";
import {
    useRouter
} from 'vue-router';
import {
    useStore
} from 'vuex';
import loseWeightSvg from "../../assets/loseWeightSvg.vue";
import femaleShapeSvg from "../../assets/femaleShapeSvg.vue";
import maleShapeSvg from "../../assets/maleShapeSvg.vue";
const router = useRouter()
const store = useStore()
const ifShowHelpLoseWeight = ref(false)
const ifShowHelpShape = ref(false)
store.commit('setVisible', {
    type: 'progressBar',
    value: false
})
</script>

<style lang="scss" scoped>
@import url("../../styles/question.scss");

.buttonCool-landing {
    --color: #63af76;
    margin: .5rem .1rem 0 .1rem;
}

.helpContent {
    position: absolute;
    z-index: 2;
    background-color: #fff;
    width: 85%;
    height: 90%;
    padding-left: 2%;
    padding-right: 2%;
    font-size: .10rem;

    &__body {
        padding-top: 30%;
    }

    &__closeBtn {
        color: #9cd54f;
        position: absolute;
        top: 1%;
        right: 9%;
        border: 1px solid #9cd54f;
        border-radius: .03rem;
        padding: 2%
    }
}

.help {
    position: absolute;
    top: 10%;
    right: 10%;
    font-size: .16rem;
    color: #9cd54f;
}

.card {
    margin: .1rem;
    width: 1.2rem;
    text-align: center;

    &__title {
        font-size: 0.2rem;
        font-weight: bold;
        color: #9cd54f;
    }

    &__subtitle {
        font-size: 0.1rem;
    }

    &--big {
        position: relative;
        text-align: left;
        margin: .1rem;
        width: 2.7rem;
        height: 2.7rem;
    }
}

.marginTop1Rem {
    margin-top: .1rem;
}

.marginTop2Rem {
    margin-top: .2rem;
}
</style>
