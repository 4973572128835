<template>
<div>
    <div class="centerDiv">
        <el-card class="card__big" shadow="hover" @click="pushLink('basic')">
            <div class="card__big__title">基本 <br /> 报告</div>
            <div class="card__big__arrow iconfont">&#xe617;</div>
        </el-card>
        <el-card class="card__big" shadow="hover" @click="pushLink('pay')">
            <div class="card__big__title">智能 <br /> 食谱</div>
            <div class="card__big__subtitle"><span class="card__big__highlight">vip</span></div>
            <div class="card__big__arrow iconfont">&#xe617;</div>
        </el-card>
    </div>
</div>
</template>

<script lang="ts" setup>
import {
    useStore
} from 'vuex'
import {
    useRouter
} from 'vue-router'
const router = useRouter()
const store = useStore()
store.commit('setVisible', {
    value: false
})
const pushLink = (link: string) => {
    if (link == "pay") {
        router.push("/pay")
    }
    if (link == "basic") {
        router.push("/basic")
    }
}
</script>

<style lang="scss" scoped>
@import url("../styles/question.scss");

.card {
    &__big {
        position: relative;
        text-align: left;
        margin: .4rem;
        width: 2.7rem;
        height: 2.7rem;
        border: 3px solid #fff;
        border-radius: .2rem;
        cursor: pointer;
        background-image: linear-gradient(to right, #b1f78b, #d0f637);

        &__title {
            font-size: .6rem;
            font-weight: bold;
            color: #1e1e1e;
        }

        &__subtitle {
            font-size: 0.3rem;
            text-shadow: 2px 2px 2px #2828283A;
        }

        &__highlight {
            font-weight: bolder;
            color: #FB0000;
        }

        &__arrow {
            position: absolute;
            right: 5%;
            top: 5%;
            background-color: #FFFFFEB4;
            height: .5rem;
            width: .5rem;
            border-radius: 20%;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
            font-size: .45rem;
        }
    }

}

.prez {
    background: url('~/assets/bg_bk.jpg') 50% 0 no-repeat fixed;
    background-size: cover;
    height: 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 0;

    &__mask {
        z-index: 1;
        width: 70%;
    }

    &__desc {
        padding: .2rem;
        margin-top: .8rem;
        margin-left: .2rem;
        margin-right: .2rem;
        color: #000000;
        font-size: .2rem;
        font-weight: bold;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        &__icon {
            flex: 1;
            text-align: left;
            max-width: .3rem;
        }

        &__text {
            flex: 2;
            text-align: right;
        }
    }

    &__desc:before {
        transition: all 0.8s;
        content: '';
        width: 0%;
        height: 100%;
        background: #d0f637;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0.2rem;
    }

    &__desc span {
        mix-blend-mode: darken;
    }

    &__desc:hover:before {
        background: #d0f637;
        width: 100%;
        border-radius: 0.2rem;
    }

}
</style>
